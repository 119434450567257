import "./App.scss";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import Nav from "./Nav";
import Main from "./Main";

function App() {
  return (
    <BrowserRouter>
      <div>
        <header className="front">
          expect<span className="primary">/digital</span>
        </header>
        <Nav />
        <Routes>
          <Route path="/" element={<Outlet />}>
            <Route index exact element={<Main />} />
            <Route path=":page" element={<Main />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
