import { NavLink } from "react-router-dom";

import "./Nav.scss";

export default function Nav() {
  return (
    <nav>
      <ul>
        <li>
          <NavLink exact="exact" to="/" />
        </li>
        <li>
          <NavLink to="/portfolio" />
        </li>
        <li>
          <NavLink to="/about-us" />
        </li>
        <li>
          <NavLink to="/contact" />
        </li>
        <li>
          <NavLink to="/hire" />
        </li>
      </ul>
    </nav>
  );
}
