import React, { Component } from "react";

export default class Modal extends Component {
  bodyClassList = document.querySelector("body").classList;

  close = (e) => {
    e.preventDefault();
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    if (!this.props.open) {
      this.bodyClassList.remove("modal-opened");
      return null;
    }

    this.bodyClassList.add("modal-opened");
    return (
      <div className="modal">
        <button className="modal-close" onClick={this.close}>
          <i className="material-icons">close</i>
        </button>
        <div>{this.props.children}</div>
      </div>
    );
  }
}
