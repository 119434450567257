import React, { Component } from "react";

import "./HeroPage.scss";

export default class HeroPage extends Component {
  render = () => (
    <section className="page front hero center">
      <div className="container">
        <div className="three merged column">
          <h1>
            Your digital partner,
            <br />
            helping you get the very best from digital tech
          </h1>
        </div>
      </div>
    </section>
  );
}
