function AboutUsPage() {
  return (
    <section className="page front center">
      <div className="container">
        <div className="two merged column half-height">
          We have a cracking team of digital experts and talents, who between
          them have extensive industry knowledge and countless years of
          experience, primarily in financial, automotive, health and hospitality
          industries: competent in determining suitable solutions and skilled in
          delivering powerful digital experiences and business value to our
          clients.
        </div>
        <div className="two merged column right half-height">
          No matter how complex and challenging your problem is, we offer to
          enhance your digital estate to deliver resilient solution and boost to
          your business processes - be it legacy software or Greenfield project.
        </div>
      </div>
    </section>
  );
}

export default AboutUsPage;
