/*global $crisp */

import React, { Component } from "react";
import onCrispReady from "./crisp";

export default class WeHirePage extends Component {
  state = {
    isTeamOnline: false,
  };

  vacancies = [
    {
      title: "Senior Frontend Engineer",
      payRange: {
        from: 36,
        to: 48,
      },
      requirements: [
        `enjoy coding`,
        `deliver code that communicates well to other developers - keep it simple, stupid. You don't stop at "it works" - you refactor and produce code that is readable and fast;`,
        `be a fast learner, open-minded to learn new technologies and approaches to solve complex challenges in simple ways`,
        `have a very good understanding of web standards and tech stacks;`,
        `provide valuable code reviews and support to other team members;`,
        `support development workflow with automated tools to ensure code meets the highest possible standards and is delivered in a resilient manner.`,
      ],
    },
    {
      title: "Senior Backend Engineer",
      payRange: {
        from: 36,
        to: 48,
      },
      requirements: [
        `enjoy coding`,
        `deliver code that communicates well to other developers - keep it simple, stupid. You don't stop at "it works" - you refactor and produce code that is readable and fast;`,
        `be a fast learner, open-minded to learn new technologies and approaches to solve complex challenges in simple ways`,
        `have a very good understanding of tech stacks;`,
        `provide valuable code reviews and support to other team members;`,
        `support development workflow with automated tools to ensure code meets the highest possible standards and is delivered in a resilient manner.`,
      ],
    },
  ];

  componentDidMount() {
    onCrispReady(() => {
      this.updateTeamOnline();
      $crisp.push([
        "on",
        "website:availability:changed",
        this.updateTeamOnline,
      ]);
    });
  }

  updateTeamOnline = () =>
    this.setState({ isTeamOnline: $crisp.is("website:available") });

  showChat = (e) => {
    e.preventDefault();
    $crisp.push(["do", "chat:open"]);
  };

  pay = (payRange) =>
    payRange.from ? (
      <p>
        We offer pay €{payRange.from}'000-{payRange.to}'000 gross a year in
        Latvia
      </p>
    ) : (
      <p>We offer pay up to €{payRange.to}'000 gross a year in Latvia</p>
    );

  render = () => (
    <section className="page front">
      <div className="container">
        <div className="three merged column">
          <div>
            <h1>We hire</h1>
            <p>No vacancies available at this time</p>
          </div>
        </div>
      </div>
    </section>
  );
}
