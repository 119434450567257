import React, { Component } from "react";

import "./Portfolio.scss";

const empty = null;

export default class Portfolio extends Component {
  render() {
    // todo serve dynamic size images based on actual container size, LOW PRIORITY
    const portfolio = [
      {
        title: "nubbio",
        link: "http://nubb.io",
        bg: null,
        points: [
          "web",
          "cross-platform desktop app",
          "WYSIWYG IDE",
          "low code process automation",
        ],
      },
      {
        title: "LADbible",
        link: "https://www.ladbible.com",
        bg: "https://res.cloudinary.com/responsive-craft-limited/image/upload/v1423683057/work/theladbible/home.png",
        points: ["web", "backend", "content delivery"],
      },
      {
        title: "Creatomus",
        link: "https://creatomus.com",
        bg: "https://res.cloudinary.com/responsive-craft-limited/image/upload/v1533742394/work/creatomus/creatomus.png",
        points: ["web", "3D"],
      },
    ];

    // all points MUST have equal length so we can align content in line
    const maxLen = portfolio.reduce(
      (res, work) => Math.max(work.points.length, res),
      0
    );
    portfolio.forEach((work) => {
      while (work.points.length < maxLen) {
        work.points.push(empty);
      }
    });

    return (
      <section className="page">
        <div className="container">
          {portfolio.map((work, idx) => (
            <div className="one merged column full-height work front" key={idx}>
              <div>
                <h1>
                  <a href={work.link} target="_blank" rel="noreferrer">
                    {work.title}
                  </a>
                </h1>
                <ul>
                  {work.points.map((point, idx) =>
                    point === empty ? (
                      <br className="computer-visible" key={idx} />
                    ) : (
                      <li key={idx}>{point}</li>
                    )
                  )}
                </ul>
                {work.bg && (
                  <div
                    className="work-img"
                    style={{ backgroundImage: `url(${work.bg})` }}
                  ></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
}
