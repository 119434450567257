/*global $crisp, L */

import React, { Component } from "react";
import onCrispReady from "./crisp";

import "./ContactPage.scss";

export default class ContactPage extends Component {
  state = {
    isTeamOnline: false,
  };

  componentDidMount() {
    onCrispReady(() => {
      this.updateTeamOnline();
      $crisp.push([
        "on",
        "website:availability:changed",
        this.updateTeamOnline,
      ]);
    });

    window.addEventListener("load", this.renderMap);
  }

  renderMap = () => {
    const warrington = [53.3917, -2.5972];
    const riga = [56.9489, 24.1064];

    const map = L.map(this.refs.map, {
      layers: [
        L.tileLayer(
          "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png"
        ),
      ],
      attributionControl: false,
      scrollWheelZoom: false,
      zoomControl: false,
      dragging: false,
    }).fitBounds([
      [warrington[0], warrington[1] - 1],
      [riga[0], riga[1] + 1],
    ]);

    const icon = new L.DivIcon();
    L.marker(warrington, { icon })
      .addTo(map)
      .bindPopup(
        'Warrington, UK<br/>+44 (0) 7543 5200 70<br/><a href="mailto:hello@expect.digital">hello@expect.digital</a>'
      );
    L.marker(riga, { icon })
      .addTo(map)
      .bindPopup(
        'Rīga, Latvia<br/>+371 28 348 356<br/><a href="mailto:hello@expect.digital">hello@expect.digital</a>'
      );
  };

  showChat = (e) => {
    e.preventDefault();
    $crisp.push(["do", "chat:open"]);
  };

  updateTeamOnline = () =>
    this.setState({ isTeamOnline: $crisp.is("website:available") });

  render = () => (
    <section className="page">
      <div className="contact">
        <div className="container">
          <div className="three merged column">
            <p>
              Would you like our help with your problem?{" "}
              {this.state.isTeamOnline
                ? "We are online and looking forward to answer any questions you may have."
                : "We are not online at the moment, but feel free to nudge us, and we'll get back to you as quickly as possible."}
              <button onClick={this.showChat}> Send us a message...</button>
            </p>
          </div>
        </div>
      </div>
      <div className="map" ref="map"></div>
    </section>
  );
}
