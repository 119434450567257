import React, { Component } from "react";

import "./Main.scss";
import ccs from "./ccs-supplier.svg";

import HeroPage from "./HeroPage";
import AboutUsPage from "./AboutUsPage";
import PortfolioPage from "./Portfolio";
import ContactPage from "./ContactPage";
import CookiePolicy from "./CookiePolicy";
import WeHirePage from "./WeHirePage";
import { useNavigate, useParams } from "react-router-dom";

// todo add "we hire" page when everything else is done
// import WeHirePage from './WeHirePage'

const pages = ["", "portfolio", "about-us", "contact", "hire"];

class MainX extends Component {
  state = {
    cookiePolicyOpen: false,
  };
  pageChanged = false;

  // currentPageIdx returns current page based on page param in url.
  get currentPageIdx() {
    const idx = pages.indexOf(this.props.params.page || "");
    if (idx === -1) {
      return 0;
    }

    if (idx >= pages.length) {
      return pages.length - 1;
    }

    return idx;
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      () => {
        const idx = Math.min(
          Math.floor(window.scrollY / window.innerHeight),
          pages.length - 1
        );

        if (idx !== this.currentPageIdx) {
          this.pageChanged = true;
          this.props.navigate(`/${pages[idx]}`);
        }
      },
      { passive: true }
    );

    this.updatePageHeights();
    window.addEventListener("resize", this.updatePageHeights.bind(this));

    this.scrollPageIntoView();
  }

  componentDidUpdate() {
    this.scrollPageIntoView();
  }

  updatePageHeights() {
    this.pageHeights.forEach(
      (v) => (v.el.style.height = `${v.viewHeightRatio}00vh`)
    );
  }

  get pageHeights() {
    return Array.from(window.document.querySelectorAll(".page")).map((el) => ({
      el,
      viewHeightRatio: Math.ceil(
        el.firstChild.clientHeight / window.innerHeight
      ),
    }));
  }

  scrollPageIntoView() {
    if (this.pageChanged) {
      this.pageChanged = false;
      return;
    }

    // calc tops for all "pages"
    const tops = this.pageHeights
      .map((v) => v.viewHeightRatio * window.innerHeight)
      .reduce(
        (r, v, i) => {
          r.push(r[i] + v);
          return r;
        },
        [0]
      );

    window.scrollTo({
      top: tops[this.currentPageIdx],
      behavior: "smooth",
    });
  }

  openCookiePolicy = (e) => {
    e.preventDefault();
    this.setState({ cookiePolicyOpen: true });
  };

  closeCookiePolicy = () => {
    this.setState({ cookiePolicyOpen: false });
  };

  render = () => (
    <main ref="main">
      <div className="container">
        <div className="grid-lines-left"></div>
        <div className="grid-lines-right"></div>
      </div>
      <HeroPage />
      <PortfolioPage />
      <AboutUsPage />
      <ContactPage />
      <WeHirePage />
      <div>
        <div className="overlay"></div>
        <CookiePolicy
          open={this.state.cookiePolicyOpen}
          onClose={this.closeCookiePolicy}
        />
      </div>
      <footer className="page">
        <div className="text container ">
          <div className="one merged column">
            <div>
              <p>Responsive Craft Ltd &copy; 2021</p>
              <img
                src={ccs}
                alt="Crown Commercial Service supplier"
                className="ccs"
              ></img>
              <br />
              <br />
              <br />
              <button onClick={this.openCookiePolicy}>Cookie Policy</button>
            </div>
          </div>
          <div className="one merged column"></div>
          <div className="one merged column"></div>
        </div>
      </footer>
    </main>
  );
}

export default function Main(props) {
  const params = useParams();
  const navigate = useNavigate();

  return <MainX {...props} params={params} navigate={navigate} />;
}
