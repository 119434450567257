import React, { Component } from "react";

import Modal from "./Modal";

export default class CookiePolicy extends Component {
  cookieDeclarationLoaded = false;

  loadCookieDeclaration() {
    if (this.cookieDeclarationLoaded) {
      return;
    }

    this.cookieDeclarationLoaded = true;
    const script = document.createElement("script");
    script.src =
      "https://consent.cookiebot.com/2df12d88-1cc8-40fd-8525-5054716d1862/cd.js";
    script.async = true;
    document.body.appendChild(script);
  }

  componentDidUpdate() {
    if (this.props.open && this.cookieDeclarationLoaded === false) {
      this.loadCookieDeclaration();
    }
  }

  onModalClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render = () => (
    <Modal open={this.props.open} onClose={this.onModalClose}>
      <h1>Cookie Policy</h1>
      <div id="CookieDeclaration"></div>
    </Modal>
  );
}
