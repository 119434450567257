let loaded = false;

const listeners = [];

window.CRISP_READY_TRIGGER = function () {
  loaded = true;
  listeners.forEach((f) => f());
};

export default function onCrispReady(f) {
  if (!loaded) {
    listeners.push(f);
  } else {
    f();
  }
}
